import request from '@/utils/request'
// 获取个案辅导列表
export function getCoachList(paging, data) {
    return request({
      url: `/archives/page/coach/${paging.p}/${paging.ps}`,
      method: 'post',
      data
    });
}
// 新增个案辅导
export function addCoach(userId, data) {
  return request({
    url: `/archives/save/${userId}`,
    method: 'post',
    data
  });
}
// 删除个案辅导
export function deleteCoach( data) {
  return request({
    url: `archives/delete`,
    method: 'delete',
    data
  });
}
// 导出个案辅导数据
export function downloadCoach( data) {
  return request({
    url: `/archives/user/export/coach`,
    method: 'post',
    data,
    responseType: 'blob'
  });
}
// 导出个案辅导报告
export function downloadCoachReport( data) {
  return request({
    url: `/archives/user/export/report`,
    method: 'post',
    data,
    responseType: 'blob'
  });
}
// 查看咨询报告
export function getCoachDetail(id, params) {
  return request({
    url: `/archives/${id}`,
    method: 'get',
    params
  });
}
// 修改咨询报告
export function updateCoachDetail(data) {
  return request({
    url: `/archives`,
    method: 'put',
    data
  });
}