<template>
    <!-- 咨询报告 -->
    <div class="viewReport" id="viewReport">
        <div class="region">
            <headerTopVue></headerTopVue>
            <div class="rg">
                <!-- 查看报告 -->
                <div class="topReport item">
                    <div class="left">
                        <span>ID: {{ $route.query.id }}-{{ evaluationInfo.name }}的咨询报告</span>
                    </div>
                    <!-- <div class="right">
                          <span>报告生成时间：{{ evaluationInfo.reportDate }}</span>
                      </div> -->
                </div>
                <!-- 个人信息 -->
                <div class="userInfo item">
                    <p class="title">个人信息</p>
                    <p>姓名：<span>{{ evaluationInfo.name }}</span></p>
                    <p>性别：<span v-if="evaluationInfo.sex === 1">男</span>
                        <span v-else-if="evaluationInfo.sex === 2">女</span>
                        <span v-else-if="evaluationInfo.sex === 3">未设置</span>
                    </p>
                    <p>出生日期：<span v-if="evaluationInfo.birthday">{{ evaluationInfo.birthday.slice(0, 10) }}</span></p>
                    <p>年龄：<span>{{ evaluationInfo.age }}</span></p>
                    <p>电话：<span>{{ evaluationInfo.phone }}</span></p>
                    <p>组织机构：<span>{{ evaluationInfo.deptName }}</span></p>
                    <p>角色：<span>{{ evaluationInfo.roleName }}</span></p>
                    <!-- <p>创建人：<span>{{ evaluationInfo.createdName }}</span></p>
                    <p>创建时间：<span>{{ evaluationInfo.createTime }}</span></p> -->
                    <p>处理级别:<el-select v-model="evaluationInfo.level" @change="submit('level')" placeholder="请选择干预级别"
                            size="small">
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                            <el-option label="三级" :value="3"></el-option>
                        </el-select>
                    </p>
                    <p>处理状态:<el-select v-model="evaluationInfo.status" @change="submit('status')" placeholder="请选择状态"
                            size="small">
                            <el-option label="未开始" :value="1"></el-option>
                            <el-option label="进行中" :value="2"></el-option>
                            <el-option label="已结束" :value="3"></el-option>
                        </el-select>
                    </p>
                </div>
                <div class="item table">
                    <p class="title">咨询信息</p>
                    <table>
                        <tr>
                            <td class="td1">咨询师</td>
                            <td>{{ evaluationInfo.consultName }}</td>
                            <td class="td1">咨询时间</td>
                            <td>
                                <div style="display: flex;">
                                    <el-date-picker v-model="evaluationInfo.consultDate" type="date" @change="submit"
                                        placeholder="选择日期" value-format='yyyy-MM-dd'>
                                    </el-date-picker>
                                    <el-time-picker is-range v-model="value1" range-separator="-" @change="submit"
                                        value-format='HH:mm:ss' start-placeholder="开始时间" end-placeholder="结束时间"
                                        placeholder="选择时间范围">
                                    </el-time-picker>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td1">咨询地点</td>
                            <td>
                                <el-input placeholder="请输入咨询地点" v-model="evaluationInfo.address" @blur="submit" size="small"
                                    clearable>
                                </el-input>
                            </td>
                            <td class="td1">预约时间</td>
                            <td>
                                <div style="display: flex;">
                                    <el-date-picker v-model="evaluationInfo.reservationDate" type="date"
                                        value-format='yyyy-MM-dd' @change="submit" placeholder="选择日期">
                                    </el-date-picker>
                                    <el-time-picker is-range v-model="value2" range-separator="-" @change="submit"
                                        value-format='HH:mm:ss' start-placeholder="开始时间" end-placeholder="结束时间"
                                        placeholder="选择时间范围">
                                    </el-time-picker>

                                    <!-- {{ evaluationInfo.reservationDate }}
                                    &nbsp;{{ evaluationInfo.reservationStartTime }}-{{ evaluationInfo.reservationEndTime
                                    }} -->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td1">咨询主题</td>
                            <td>
                                <!-- {{ evaluationInfo.subject }} -->
                                <el-input placeholder="请输入咨询主题" v-model="evaluationInfo.subject" @blur="submit" size="small"
                                    clearable>
                                </el-input>
                            </td>
                            <td class="td1">咨询方式</td>
                            <td>
                                <!-- {{evaluationInfo.mode}} -->
                                <el-select v-model="evaluationInfo.mode" placeholder="请选择咨询方式" @change="submit" size="small"
                                    clearable>
                                    <el-option label="电话" :value="1">
                                    </el-option>
                                    <el-option label="面谈" :value="2">
                                    </el-option>
                                    <el-option label="视频" :value="3">
                                    </el-option>
                                    <el-option label="其他" :value="4">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="showIcon item">
                    <p class="title">咨询记录</p>
                    <p class="title2">情况说明
                        <el-button type="primary" size="small" v-if="!memoStatus" @click="change('memoStatus')">编辑
                        </el-button>
                        <el-button type="primary" size="small" v-else @click="submit('memo')">保存</el-button>
                    </p>
                    <div class="iconContent">
                        <el-input type="textarea" :readonly="!memoStatus" :autosize="{ minRows: 4 }" placeholder="请输入内容"
                            v-model="evaluationInfo.memo">
                        </el-input>
                    </div>
                </div>
                <div class="showIcon item">
                    <p class="title2">咨询记录
                        <el-button type="primary" size="small" v-if="!proposalStatus" @click="change('proposalStatus')">
                            编辑
                        </el-button>
                        <el-button type="primary" size="small" v-else @click="submit('proposal')">保存</el-button>
                    </p>
                    <div class="iconContent">
                        <el-input type="textarea" :readonly="!proposalStatus" :autosize="{ minRows: 4 }" placeholder="请输入内容"
                            v-model="evaluationInfo.record">
                        </el-input>
                    </div>
                </div>
                <div class="showIcon item">
                    <p class="title2">咨询师意见
                        <el-button type="primary" size="small" v-if="!measuresStatus" @click="change('measuresStatus')">
                            编辑
                        </el-button>
                        <el-button type="primary" size="small" v-else @click="submit('measures')">保存</el-button>
                    </p>
                    <div class="iconContent">
                        <el-input type="textarea" :readonly="!measuresStatus" :autosize="{ minRows: 4 }" placeholder="请输入内容"
                            v-model="evaluationInfo.opinion">
                        </el-input>
                    </div>
                </div>
                <div class="showIcon item">
                    <p class="title2">跟踪反馈
                        <el-button type="primary" size="small" v-if="!feedbackStatus" @click="change('feedbackStatus')">
                            编辑
                        </el-button>
                        <el-button type="primary" size="small" v-else @click="submit('feedback')">保存</el-button>
                    </p>
                    <div class="iconContent">
                        <el-input type="textarea" :readonly="!feedbackStatus" :autosize="{ minRows: 4 }" placeholder="请输入内容"
                            v-model="evaluationInfo.feedback">
                        </el-input>
                    </div>
                </div>
                <BottomVue></BottomVue>
                <div class="btn">
                    <span @click="back">返回</span>
                    <span class="export" @click="ExportWord">导出报告</span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import JSZipUtils from "jszip-utils"
import docxtemplater from "docxtemplater"
import { saveAs } from 'file-saver'
import PizZip from 'pizzip';
import html2canvas from 'html2canvas';

import BottomVue from "@/components/Bottom.vue";
import headerTopVue from "@/components/headerTop.vue";
import { getCoachDetail, updateCoachDetail } from '@/api/healthRecords/coach';
export default {
    data() {
        return {
            value1: null,
            value2: null,
            evaluationInfo: {
                age: null,
                birthday: null,
                date: null,
                sex: null,
                telephone: null,
                birthday: null,
                nickname: null,
                memo: null,
                level: null, // 干预等级
                status: null, // 干预状态
            },
            importInfo: null, // 导出数据
            memoStatus: false, // 情况说明状态
            proposalStatus: false, // 干预建议
            feedbackStatus: false, //跟踪反馈
            measuresStatus: false // 干预措施
        }
    },
    methods: {
        // 获取数据
        async getList(val) {
            const res = await getCoachDetail(val);
            console.log(res);
            if (res.code === 200) {
                // this.type = res.data.type;
                this.evaluationInfo = res.data;
                let val1 = [];
                let val2 = [];
                if (this.evaluationInfo.reservationStartTime) {
                    val2.push(this.evaluationInfo.reservationStartTime)
                    val2.push(this.evaluationInfo.reservationEndTime)
                    this.value2 = val2;
                } else {
                    this.value2 = ['', '']
                }
                if (this.evaluationInfo.startTime) {
                    val1.push(this.evaluationInfo.startTime)
                    val1.push(this.evaluationInfo.endTime)
                    this.value1 = val1;
                } else {
                    this.value1 = ['', '']
                }

                this.importInfo = JSON.parse(JSON.stringify(res.data));
            }

            // 处理导出Word时数据-------------------
            // 出生日期
            if (this.importInfo.birthday === null) {
                this.importInfo.birthday = ''
            }
            // 年龄
            if (this.importInfo.age === null) {
                this.importInfo.age = ''
            }
            // 转换性别

            if (this.importInfo.sex === 1) {
                this.importInfo.sex = '男'
            } else if (this.importInfo.sex === 2) {
                this.importInfo.sex = '女'
            } else if (this.importInfo.sex === 3) {
                this.importInfo.sex = '未设置'
            } else {
                this.importInfo.sex = ''
            }
            // 转换预警级别
            if (this.importInfo.level === 1) {
                this.importInfo.level = '一级'
            } else if (this.importInfo.level === 2) {
                this.importInfo.level = '二级'
            } else if (this.importInfo.level === 3) {
                this.importInfo.level = '三级'
            } else if (this.importInfo.level === null) {
                this.importInfo.level = '未设置'
            }
            // 转换处理状态
            if (this.importInfo.status === 1) {
                this.importInfo.status = '未开始'
            } else if (this.importInfo.status === 2) {
                this.importInfo.status = '进行中'
            } else if (this.importInfo.status === 3) {
                this.importInfo.status = '已结束'
            }
            // 转换处理状态
            if (this.importInfo.mode === 1) {
                this.importInfo.mode = '电话'
            } else if (this.importInfo.mode === 2) {
                this.importInfo.mode = '视频'
            } else if (this.importInfo.mode === 3) {
                this.importInfo.mode = '会议'
            } else if (this.importInfo.mode === 4) {
                this.importInfo.mode = '其他'
            } else {
                this.importInfo.mode = ''
            }

            if (this.importInfo.memo === null) {
                this.importInfo.memo = '无'
            }
            if (this.importInfo.opinion === null) {
                this.importInfo.opinion = '无'
            }
            if (this.importInfo.record === null) {
                this.importInfo.record = '无'
            }
            if (this.importInfo.feedback === null) {
                this.importInfo.feedback = '无'
            }
        },
        // 点击返回
        back() {
            if (this.$route.path === '/home/fileDetails5') {
                this.$router.push('/home/healthRecords/record')
            } else if (this.$route.path === '/home/appointmentReport') {
                this.$router.push('/home/appointmentConsultation')
            } else {
                this.$router.push('/home/coach')
            }
        },
        // 点击编辑
        change(val) {
            console.log(this.value2)
            this.getList(this.$route.query.id);
            this.$forceUpdate();
            // console.log(val);
            if (val === 'measuresStatus') {
                this.measuresStatus = true;
                this.feedbackStatus = false;
                this.proposalStatus = false;
                this.memoStatus = false;
            } else if (val === 'feedbackStatus') {
                this.feedbackStatus = true;
                this.measuresStatus = false;
                this.proposalStatus = false;
                this.memoStatus = false;
            } else if (val === 'proposalStatus') {
                this.proposalStatus = true;
                this.feedbackStatus = false;
                this.measuresStatus = false;
                this.memoStatus = false;
            } else if (val === 'memoStatus') {
                this.memoStatus = true;
                this.feedbackStatus = false;
                this.measuresStatus = false;
                this.proposalStatus = false;
            }

        },
        // 提交修改
        async submit(val) {
            // console.log(val)
            // let msg = {val: this.evaluationInfo.val}
            // console.log(msg)
            console.log(this.value2)
            let msg = {
                address: this.evaluationInfo.address, // 地点

                // consultantId: this.evaluationInfo.consultantId,
                // createTime: this.evaluationInfo.createTime,
                // createdId: this.evaluationInfo.createdId, 

                feedback: this.evaluationInfo.feedback,  //跟踪反馈
                id: this.evaluationInfo.id, // 当前报告的id
                level: this.evaluationInfo.level,  // 处理级别
                status: this.evaluationInfo.status,  // 处理状态
                memo: this.evaluationInfo.memo,  // 情况说明
                mode: this.evaluationInfo.mode,
                opinion: this.evaluationInfo.opinion,  // 咨询意见
                record: this.evaluationInfo.record,  //咨询记录
                consultDate: this.evaluationInfo.consultDate,
                reservationDate: this.evaluationInfo.reservationDate,
                // consultDate: this.evaluationInfo.consultDate,
                // reservationDate: this.evaluationInfo.reservationDate,
                reservationEndTime: this.value2[1],
                reservationStartTime: this.value2[0],
                startTime: this.value1[0],
                endTime: this.value1[1],
                subject: this.evaluationInfo.subject, //主题
                // updateTime: this.evaluationInfo.updateTime, 
                // userId: this.evaluationInfo.userId
            };
            // let info = { id: this.$route.query.id, ...msg };
            console.log(msg);

            const res = await updateCoachDetail(JSON.stringify(msg));
            console.log(res);
            if (res.code === 200) {
                this.getList(this.$route.query.id);
                this.measuresStatus = false;
                this.feedbackStatus = false;
                this.proposalStatus = false;
                this.memoStatus = false;
                this.$message({
                    showClose: true,
                    message: '修改成功！',
                    type: 'success'
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.msg,
                    type: 'error'
                });
            }
        },
        // 实现导出页面为图片
        // ---------------分割线-------------------
        dataURLToBlob(dataurl) {//ie 图片转格式
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime })
        },
        downloadResult(name) {
            let canvasID = document.getElementById('viewReport');
            let a = document.createElement('a');
            html2canvas(canvasID).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = "none";
                a.style.display = "none";
                document.body.removeChild(dom);
                let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
                a.setAttribute("href", URL.createObjectURL(blob));
                a.setAttribute("download", name + ".png")
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(blob);
                document.body.removeChild(a);
            });
        },
        // 点击导出
        printOut() {
            let name = `${this.evaluationInfo.name}-咨询报告`
            // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
            // $(window).scrollTop(0); // jQuery 的方法
            document.body.scrollTop = 0; // IE的
            document.documentElement.scrollTop = 0; // 其他
            this.downloadResult(name)
        },
        // ---------------分割线-------------------
        // 导出word
        ExportWord() {
            //这里要引入处理图片的插件，下载docxtemplater后，引入的就在其中了
            var ImageModule = require('docxtemplater-image-module-free');
            var _this = this;
            //Word路径，在public文件下(CLI3)
            JSZipUtils.getBinaryContent("consultImport.docx", function (error, content) {
                if (error) {
                    throw error
                };
                let opts = {}
                opts.centered = true;
                opts.fileType = "docx";
                opts.getImage = (tag) => {
                    return _this.base64DataURLToArrayBuffer(tag);
                }
                opts.getSize = () => {
                    return [600, 400]//这里可更改输出的图片宽和高
                }
                let zip = new PizZip(content);
                let doc = new docxtemplater();
                doc.attachModule(new ImageModule(opts));
                doc.loadZip(zip);
                doc.setData({
                    ..._this.importInfo, //基础信息
                });
                try {
                    doc.render()
                } catch (error) {
                    var e = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                        properties: error.properties,
                    }
                    console.log(JSON.stringify({
                        error: e
                    }));
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })
                saveAs(out, `${_this.evaluationInfo.name}的咨询报告报告.docx`)
            })
        },
        //这里是处理图片的代码
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }
    },
    created() {
        this.getList(this.$route.query.id)
        // this.$router.query.id 
    },
    components: {
        BottomVue, headerTopVue
    }
}
</script>
  
<style lang="scss" scoped>
.viewReport {

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 150px;
    }

    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
        width: 220px;
    }

    ::v-deep .el-select>.el-input {
        width: 370px !important;
    }

    .region {
        background: white;
        margin: auto;

        .table {
            table {
                border-collapse: collapse;

                td {
                    border: 1px solid #ccc;
                    padding: 0px 15px;
                    height: 32px;
                    color: #7D7D7D;
                }

                .td1 {
                    background-color: rgb(248, 248, 248);
                    color: #6b6b6b;
                }
            }
        }

        .item {
            margin-bottom: 30px;
        }


        .btn {
            display: flex;
            align-items: center;
            height: 40px;
            width: 100%;
            background: var(--btn-color);
            border-radius: 4px 0px 0px 4px;
            color: white;

            span {
                display: inline-block;
                width: 50%;
                box-sizing: border-box;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                // line-height: 100%;
            }

            .export {
                border-left: 1px solid #FFFFFF;
            }
        }





        .rg {
            .topReport {
                // width: 650px;
                padding: 0px 10px;
                height: 40px;
                background: var(--btn-color);
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    color: white;
                    font-size: 14px;
                    line-height: 40px;
                }

                .left,
                .right {
                    // line-height: 40px;
                    height: 40px;
                }
            }
        }

        .title {
            font-weight: 600;
            font-size: 16px;
        }

        .title2 {
            // font-weight: 600;
            font-size: 16px;

            .el-button {
                margin-left: 10px;
            }
        }

        p {
            font-size: 14px;
            color: #303030;

            span {
                color: #7D7D7D;
                ;
            }
        }
    }
}

@media screen and (max-width: 2160PX)and (min-width: 751PX) {
    .region {
        width: 900px;

        .rg {
            padding: 40px 100px;

            .topReport {
                width: 650px;
                // height: 40px;
                // background: #028DA9;
            }
        }

        // background: white;

    }
}

@media screen and (max-width: 750PX) {
    .region {
        width: 100%;

        .rg {
            padding-top: 20px;
            width: 90%;
            margin: auto;
        }
    }
}
</style>